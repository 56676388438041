import { BsFillEnvelopeAtFill, BsLinkedin } from 'react-icons/bs';


export default function ContactDetails() {
    const linkedinUrl = "https://www.linkedin.com/in/liamdbaker";
    const emailAddress = "liam@fixster.solutions"

    return (
        <div>
            <section className="py-5 no-bottom-padding" id="contact">
                {/* <!-- Contact form--> */}
                <div className="bg-light py-5 px-4 px-md-5 mb-5 no-bottom-padding">
                    <div className="text-center mb-5">
                        <h1 className="fw-bolder">Get in touch</h1>
                        <p className="lead fw-normal text-muted mb-0">We'd love to hear from you</p>
                    </div>
                    <div className="row gx-5 justify-content-center">
                        <div className="col-lg-8">
                            <div className="row gx-5 row-cols-1 row-cols-md-2">
                                <div className="text-center mb-5">
                                    <a href={`mailto:` + emailAddress}>
                                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><BsFillEnvelopeAtFill />
                                        </div> <p> Send us an email</p>
                                    </a>
                                </div>

                                <div className="text-center mb-5">
                                    <a href={linkedinUrl} target={`_blank`}>
                                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><BsLinkedin />
                                        </div> <p>Contact us on Linkedin</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
import { BsFillDiagram3Fill, BsFillGearFill, BsRobot, BsTools, BsFillFileEarmarkMedicalFill } from 'react-icons/bs';

export default function Services() {
    var services = [
        {
            name: "DevOps",
            description: "Bespoke solutions for your development workflows. Implementation of CI/CD pipelines, artefact storage, integration of test automation and much more.",
            logo: <BsFillGearFill/>
        },
        {
            name: "AWS Architecture",
            description: "Design and development of AWS architecture tailored to workflow requirements. We can utilise the full range of AWS managed services to derisk deployments and shift management to the cloud service provider.",
            logo: <BsFillDiagram3Fill/>
        },
        {
            name: "Automation",
            description: "Streamline your development workflow by utilising deployment and test automation for your IT project. Get us to implement CI/CD pipelines that can perform tasks such as running test cases, building software artefacts and containers, provisioning infrastructure and releasing products.",
            logo: <BsRobot/>
        },
        {
            name: "System Engineering",
            description: "Maintenance and support of business critical IT solutions. Installation and configuration of system components and software such as firewalls, proxies, webservers and anti-virus scanners. Experience managing RedHat-based Linux distributions.",
            logo: <BsTools/>
        },
        {
            name: "Short and long-term Contracting",
            description: "Professional IT services for your immediate project needs, whether that is to get a new or existing project up and running smoothly, or simply solve some short-term staffing shortages.",
            logo: <BsFillFileEarmarkMedicalFill/>
        }
    ]

    return (
        <section className="py-5 no-bottom-padding" id="services">
            <div className="container px-5 my-5">
                <div className="row gx-5">
                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <h2 className="fw-bolder mb-0">A better way to build.</h2>
                    </div>
                </div>
                    {/* <div className="col-lg-8"> */}
                    <br></br>
                        <div className="row gx-5 row-cols-1 row-cols-md-3">
                            {services.map((service, i) => {
                                return (
                                    <div className="col mb-5 h-100" key={i}>
                                        <div className={`feature bg-gradient text-white rounded-3 mb-3 feature-` + i}>
                                            {service.logo}
                                        </div>
                                        <h2 className="h5">{service.name}</h2>
                                        <p className="mb-0">{service.description}</p>
                                    </div>
                                )
                            })}
                        </div>
                    {/* </div> */}
                </div>

        </section>
    )
}
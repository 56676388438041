
const data = [
    {
        title: "System Architect and DevOps Consultant",
        dates: "Mar '22 - Current",
        header_image: "dubai.jpg",
        client_logo: "TPC-logo.png",
        badges: ["AWS", "Terraform", "Datadog", "Architecture", "Python"].sort(),
        shortDescription: "Taking a key role in the redesign and rollout of new and existing system components. Growing and supporting the on-site DevOps team.",
        longDescription: "",
        clients: "TPConnects",
        location: "Dubai, UAE"
    },
    {
        title: "System Architect",
        dates: "Jan '22 - Mar '22",
        header_image: "plane-wing.jpg",
        client_logo: "fctg-logo.jpeg",
        badges: ["AWS", "Terraform", "Datadog", "Architecture", "Python"].sort(),
        shortDescription: "Supporting the delivery of existing products, focused around NDC content delivery for airlines and travel sellers.",
        longDescription: "",
        clients: "Flight Centre Travel Group",
        location: "New Malden, UK"
    },
    {
        title: "DevOps, System and Release Engineer",
        dates: "Sep '18 - Jan '22",
        header_image: "satellite-sensor.jpg",
        client_logo: "cgi-logo.jpg",
        badges: ["DevOps", "Agile", "AWS", "VMWare", "Ansible", "Python"],
        shortDescription: "Project Aurora solves issues of observability and tracking for UK space sector clients and forms part of the UK's critical national infrastructure (CNI).",
        longDescription: "",
        clients: "CGI - Space, Defence and Intelligence",
        location: "Leatherhead, UK"
    },

];


export default function Portfolio() {

    var cards = data.map((element, i) => {
        return (
            <div className="col-lg-4 mb-5" key={i}>
                <div className="card h-100 shadow border-0">
                    <img className="card-img-top" src={process.env.PUBLIC_URL + '/portfolio-images/' + element.header_image} alt="..." />
                    <div className="card-body p-4">
                        <a className="text-decoration-none link-dark stretched-link" href="#!"><h5 className="card-title mb-3">{element.title}</h5></a>

                        {element.badges.sort().map((badge, o) => {
                            return (<div className="badge bg-primary bg-gradient rounded-pill mb-2" key={i + o}>{badge}</div>)
                        })}

                        <p className="card-text mb-0">{element.shortDescription}</p>
                    </div>
                    <div className="card-footer p-4 pt-0 bg-transparent border-top-0">
                        <div className="d-flex align-items-end justify-content-between">
                            <div className="d-flex align-items-center">
                                <img className="rounded-circle me-3 portfolio-logo" src={element.client_logo ? process.env.PUBLIC_URL + '/portfolio-images/' + element.client_logo : "https://dummyimage.com/40x40/ced4da/6c757d"} alt="..." />
                                <div className="small">
                                    <div className="fw-bold">{element.clients}</div>
                                    <div className="text-muted">{element.dates}</div>
                                    <div className="text-muted">{element.location}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    })

    return (
        // Title
        <section className="py-5" id="portfolio">
            <div className="container px-5 my-5">
                <div className="row gx-5 justify-content-center">
                    <div className="col-lg-8 col-xl-6">
                        <div className="text-center">
                            <h2 className="fw-bolder">Portfolio</h2>
                        </div>
                    </div>
                </div>
                <div className="row gx-5">

                    {cards}

                </div>
            </div>
        </section>
    )
}
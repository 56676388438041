// import Carousel from 'react-bootstrap/Carousel'
import React from 'react';
import TextTransition, { presets } from 'react-text-transition';

const data = [
    {
        name: "Manpreet Singh",
        feedback: "Liam is a highly skilled professional who consistently delivers quality work. His extensive knowledge and expertise in DevOps have been invaluable in managing and maintaining our company's infrastructure.",
        position: "Test Engineer",
        company: "CGI"
    },
    {
        name: "James Hurley",
        feedback: "One of Liam's greatest strengths is his ability to adapt to multiple domains, making him an invaluable asset to any team.",
        position: "Lead Data Engineer",
        company: "TPConnects"
    }
]

export default function Testimonies() {

    const [index, setIndex] = React.useState(0);

    React.useEffect(() => {
        const intervalId = setInterval(
            () => {
                setIndex((index) => index + 1);
            },
            5000, // every 8 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);

    return (
        <div className="py-5 bg-light">
            <div className="container px-5 my-6">
                <div className="row gx-5 justify-content-center">
                    <div className="col-lg-10 col-xl-7">
                        <TextTransition springConfig={presets.stiff} className="text-center justify-content-center">{
                            <div className="carousel-inner row gx-5 justify-content-center">
                                <div className="fs-4 mb-4 fst-italic">{data[index % data.length].feedback}</div>
                                <div className="">
                                    <div className="fw-bold">
                                        {data[index % data.length].name}
                                        <span className="fw-bold text-primary mx-1">/</span>
                                        <span>
                                        {data[index % data.length].position}
                                        </span>
                                        <br></br>
                                        <span>
                                        {data[index % data.length].company}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                        </TextTransition>
                    </div>
                </div>
            </div>
        </div>

    );
}
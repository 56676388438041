import React, { useState } from 'react';
import { IconContext } from 'react-icons/lib';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineClose } from 'react-icons/ai';



export default function Navbar() {

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    // Changes the state of the navbar to the opposite value.
    const toggleMobileNavbar = () => {
        setIsNavCollapsed(!isNavCollapsed)
    }

    // Used for closing navigation when jumping to another section.
    const closeNav = () => {
        setIsNavCollapsed(true)
    }


    return (
        // Navbar
        <nav className={`navbar navbar-expand-lg navbar-dark bg-dark sticky-top`} id="navbar">
            <div className="container px-5">

                {/* Company Logo */}
                <a className="navbar-brand" href="/">
                    <img className="header-img" alt="Company logo" src={process.env.PUBLIC_URL + '/header-logo.png'} />
                </a>

                {/* Mobile Navbar Toggle Button */}
                <button className="navbar-toggler collapsed" type="button"
                    onClick={toggleMobileNavbar}
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded={!isNavCollapsed ? true : false}
                    aria-label="Toggle navigation"
                >
                    <IconContext.Provider value={{ color: 'white', size: '25px' }}>
                        {/* Hamburger Icon */}
                        <span >
                            {
                                !isNavCollapsed ? <AiOutlineClose /> : <GiHamburgerMenu />
                            }
                        </span>
                    </IconContext.Provider>

                </button>

                {/* Navigation Items Container */}
                <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarSupportedContent" style={{}}>

                    {/* Navigation List */}
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">

                        {/* Navigation Links */}
                        <li className="nav-item"><a className="nav-link" href="#home" onClick={closeNav}>Home</a></li>
                        <li className="nav-item"><a className="nav-link" href="#services" onClick={closeNav}>Services</a></li>
                        <li className="nav-item "><a className="nav-link" href="#portfolio" onClick={closeNav}>Portfolio</a></li>
                        <li className="nav-item "><a className="nav-link" href="#expertise" onClick={closeNav}>Technical Expertise</a></li>
                        <li className="nav-item"><a className="nav-link" href="#contact" onClick={closeNav}>Contact</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
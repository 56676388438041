import { React } from 'react';

import Typewriter from "typewriter-effect";

const typeWriterArray = ["maintainable solutions", "technical proficiency", "professional skills", "cutting-edge technologies", "cloud platforms", "performant solutions", "infrastructure as code", "automation", "industry expertise", "microservice architectures", "scalable systems", "data-driven decisions"]

function HomeHeaderText() {
  return (
    <>
      <header className="bg-dark py-5" id="home">
        <div className="container px-5">
          <div className="row gx-5 align-items-center justify-content-center">
            <div className="col-lg-8 col-xl-7 col-xxl-6">
              <div className="my-5 text-center text-xl-start">
                <h1 className="fw-bolder text-white mb-2">
                  Solving complex problems with <Typewriter
                    onInit={() => {
                      return void 0
                    }}
                    options={{
                      strings: typeWriterArray,
                      autoStart: true,
                      loop: true,
                      delay: 50,
                      deleteSpeed: 50,
                      wrapperClassName: "title-text-highlight",
                      cursorClassName: "title-text-highlight"
                    }}></Typewriter>
                </h1>
                <p className="lead fw-normal text-white-50 mb-4">Contact us to find out how our services can support you with DevOps, System Engineering, AWS Architecture and Deployment activites.</p>
                <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                  <a className="btn btn-primary btn-lg px-4 me-sm-3" href="#contact">Contact Us</a>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center"><img className="img-fluid rounded-3 my-5" src={process.env.PUBLIC_URL + "/banner.jpg"} alt="..." /></div>
          </div>
        </div>
      </header>
    </>
  )
}

export default HomeHeaderText;
import { SiAnsible, SiAngular, SiAmazonaws, SiGnubash, SiCisco, SiDocker, SiGit, SiJenkins, SiJfrog, SiLinux, SiPython, SiTerraform, SiConfluence } from 'react-icons/si';
import { IoHardwareChipOutline } from 'react-icons/io5';
import { AiOutlineCloud } from 'react-icons/ai';
import { FaJava } from 'react-icons/fa'
import { DiJira } from 'react-icons/di'
import { BsFillDatabaseFill } from 'react-icons/bs'
import { GrVmware } from 'react-icons/gr'

function compareNames( a, b ) {
    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
  }
  

export default function Expertise() {
    const competancies = [
        {
            name: "Ansible",
            description: "Experienced in writing and updating Ansible modules used for development, test and production deployments. Created playbooks to deploy COTS and bespoke software components as part of a CI/CD pipelines. Setup and managed deployments to multiple environments, utilising numerous ansible inventories partitioned into host groups.",
            icon: <SiAnsible />,
        },
        {
            name: "Angular and React",
            description: "Created simple, single-page applications, using React and Angular frameworks/libraries. Made updates to existing Angular products making use of framework specific syntax such as NgFor.",
            icon: <SiAngular />,

        },
        {
            name: "AWS",
            description: "Extensive experience designing and deploying various workloads to AWS, utilising services such as ECS, EC2, Lambda, RDS, DynamoDB, Route53, ACM, KMS, S3, VPC, IAM, and many more.",
            icon: <SiAmazonaws />,
        },
        {
            name: "Bash",
            description: "Experience writing utility scripts to make development quicker, and complex actions repeatable.",
            icon: <SiGnubash />,
        },
        {
            name: "Cisco vASA",
            description: "Deployed and configured Cisco's virtual ASA firewall within AWS, configuring access credentials, syslog forwarding to internal log services and setting up ACL rules to allow permitted traffic access to the system (and block everything else).",
            icon: <SiCisco />,
        },
        {
            name: "CloudFormation",
            description: "Experience working with CloudFormation stacks to provision AWS cloud resources, with further configuration being carried out using Ansible.",
            icon: <AiOutlineCloud />,
        },
        {
            name: "Confluence",
            description: "Advocates for documenting all decisions and useful information. Extensive creation of well-structured documentation for new joiners, technical design work (architectural blueprints), meeting notes and decision logs.",
            icon: <SiConfluence />,
        },
        {
            name: "Docker",
            description: "Experience building Docker images as part of CI/CD pipelines for production workloads, upgrading docker base images and deploying container images onto ECS clusters.",
            icon: <SiDocker />,
        },
        {
            name: "Git and SCM",
            description: "Worked with various source control management (SCM) systems including GitLab, GitHub and BitBucket. Integrated repository actions with CI/CD platforms such as GitHub Actions, GitLab CI/CD and BitBucket webhooks with Jenkins.",
            icon: <SiGit />,
        },
        {
            name: "Hardware",
            description: "Past experience configuring NetApp HCI, Mellanox Switches and HP Servers. Experience using KVMs configuring networking on a newly installed cluster.",
            icon: <IoHardwareChipOutline />,
        },
        {
            name: "Java",
            description: "Creation and adaption of SpringBoot APIs used within a Microservice Architecture. Also advised Java developers towards best practise regarding security patching of dependencies, and use of static code analysis tools such as SonarQube and Snyk.",
            icon: <FaJava />,
        },
        {
            name: "Jenkins",
            description: "Design and development of automation pipelines for development use within project teams. Creation of shared 'Jenkins Library' to house common functions and utilities used throughout pipelines such as retrieval of stored artifacts. This also helps work around the Jenkins pipeline 'lines of code' limits.",
            icon: <SiJenkins />,
        },
        {
            name: "JFrog Artifactory, Xray",
            description: "Managed and upgraded locally hosted instances of Artifactory. Migrated Artifactory, jumping multiple major versions into the new 'JFrog Platform' and debugged issues along the way. Also liaised with JFrog support in resolving connectivity between our Artifactory Core and Artifactory DMZ instances, successfully resolving in a short time period.",
            icon: <SiJfrog />,
        },
        {
            name: "Jira",
            description: "Used Jira as an engineer, team leader and to oversee implementation projects. Used Jira to track progress against tickets, records updates, raise awareness of bugs, plan implementation of features, and estimate/scope work with the team during estimation activities. Utilised the big picture plugin to map cross-team dependencies with internal stakeholders during project increment planning sessions.",
            icon: <DiJira />,
        },
        {
            name: "Linux",
            description: "Hands-on experience managing and rolling out VM environments in both EC2 and VMWare that utilise RHEL, CentOS and Amazon Linux. Experience configuring user permissions, anti-virus configuration, blocking loading of kernel modules for security compliance (RHEL-CIS), networking, service configuration and general debugging. Experienced in use of OPNsense for development firewall testing. Avid home user of Ubuntu and other Debian-based distros.",
            icon: <SiLinux />,
        },
        {
            name: "Python",
            description: "Use of python for configuring simple mock servers used during automation tests, scripting interactions with AWS APIs (boto3) to automate common and mundane tasks, and for writing simple processing tasks to be hosted on AWS lambda.",
            icon: <SiPython />,
        },
        {
            name: "Databases Management",
            description: "Hands-on experience deploying and administering relational databases hosted on PostgreSQL, RDS MySQL and AWS Aurora. Solely responsible for the creation and update of Ansible roles for deploying PostgreSQL databases within a virtualised environment. Experience with NoSQL datastores (DynamoDB, MongoDB) and led the redesign work for moving from RDS MySQL to DynamoDB for a system critical workload (order management).",
            icon: <BsFillDatabaseFill />,
        },
        {
            name: "Terraform",
            description: "Advocate for using Infrastructure as Code (IaC) wherever possible, with extensive use and promotion of Terraform in a professional environment (even the infrastructure hosting this website is managed with Terraform). Fully automated creation of proof-of-concept solutions using Terraform to ensure repeatability for other individuals. Migrated a whole organisation’s AWS tech stack over to Terraform from manually provisioned infrastructure, maturing their deployment processes.",
            icon: <SiTerraform />,
        },
        {
            name: "VMWare",
            description: "Setup and pre-configuration of a new VMWare cluster, based on NetApp HCI hardware, before delivery to client-site. Design and implementation of an end-to-end semi-automated deployment solution, including exhaustive documentation. Migrated AWS AMIs into VMWare compatible virtual machine templates to minimise inconsistencies between development and production environments.",
            icon: <GrVmware />,
        },

    ]

    const button_style_ids = [0, 1, 2, 3, 4]

    return (
        <section className="py-5 no-bottom-padding" id="expertise">
            <div className="container px-5 my-5">
                <div className="row gx-5">
                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <h2 className="fw-bolder mb-0">Our technical expertise.</h2>
                    </div>
                </div>
                <br></br>

                        <div className="row gx-5 row-cols-1 row-cols-md-3">
                            {competancies.sort(compareNames).map((competancy, i) => {
                                return (
                                    <div key={i}>
                                        <div className="col mb-5 h-100" >
                                            <div className={`feature bg-gradient text-white rounded-3 mb-3 feature-` + button_style_ids[i % button_style_ids.length]}>
                                                {competancy.icon}
                                            </div>
                                            <h2 className="h5">{competancy.name}</h2>
                                            <p className="mb-0">{competancy.description}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
        </section >
    )
}
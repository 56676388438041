function FixsterFooter() {
    const currentYear = new Date().getFullYear()

    return (
        <footer className="bg-dark py-4 mt-auto">
            <div className="container px-5">
                <div className="row align-items-center justify-content-between container flex-column flex-sm-row">
                    <div className="col-auto"><div className="small m-0 text-white">Copyright &copy; Fixster Solutions {currentYear}</div></div>
                    <div className="col-auto"><div className="small m-0 text-white">Developed and Deployed by Liam Baker of Fixster Solutions</div></div>
                    <div className="col-auto">
                        <a className="link-light small" href="#contact">Contact</a>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="align-items-center small m-0 text-white" style={{textAlign: `center`}}>
                    <a href="https://www.freepik.com/free-photo/cloud-computing-banner-background-smart-city_16016425.htm#page=2&query=technology&position=27&from_view=search&track=sph">Header Image by rawpixel.com</a> on Freepik
                </div>
            </div>
        </footer>
    )
}

export default FixsterFooter
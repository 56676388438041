import React from 'react';

import ContactDetails from './ContactDetails';
import Expertise from './Expertise';
import Portfolio from './Portfolio';
import Services from './Services';
import Testimonies from './Testimonies';
import HomeHeader from './HomeHeader';


function Home() {
    return (
        <div className="d-flex flex-column h-100">
            <HomeHeader />
            <Services />
            <main className="flex-shrink-0">
                <Portfolio />
                <Testimonies />
                <Expertise />
                <ContactDetails />
            </main>
        </div>
    )
}

export default Home
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import Nav from './components/Navbar'
import Home from './components/Home';
import FixsterFooter from './components/Footer'

function App(){
  return (
    <>
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route exact path="/" Component={Home}></Route>
        </Routes>
      </BrowserRouter>
      <FixsterFooter />
    </>
  )
}

export default App;
